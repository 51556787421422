import React from 'react'
import {Box, Header, Heading, StyledOcticon, Text, Tooltip} from '@primer/components'
import Layout from '../components/Layout'
import {
    BriefcaseIcon,
    CodeIcon,
    CodeSquareIcon,
    KebabHorizontalIcon,
    MailIcon,
    MarkGithubIcon,
    MilestoneIcon,
    MortarBoardIcon,
    PlusIcon,
    TypographyIcon
} from "@primer/octicons-react";
import '../styles/cv.css'
import 'katex/dist/katex.min.css'
import {Link} from "gatsby";
import {ReactComponent as TelegramIcon} from '../svg/telegram.svg'
import styled from "styled-components";

const StrokeSpan = styled.span`
  fill: ${props => props.strokeColor};
  vertical-align: middle;
  margin-right: 8px;
  font-size: 18px;
`

const log2 = '<span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><msub><mo><mi>log</mi><mo>⁡</mo></mo><mn>2</mn></msub><mo stretchy="false">(</mo><mtext mathvariant="italic">BigInteger</mtext><mo stretchy="false">)</mo></mrow><annotation encoding="application/x-tex">\\log_2(\\text{\\it BigInteger})</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:1em;vertical-align:-0.25em;"></span><span class="mop"><span class="mop">lo<span style="margin-right:0.01389em;">g</span></span><span class="msupsub"><span class="vlist-t vlist-t2"><span class="vlist-r"><span class="vlist" style="height:0.20696799999999996em;"><span style="top:-2.4558600000000004em;margin-right:0.05em;"><span class="pstrut" style="height:2.7em;"></span><span class="sizing reset-size6 size3 mtight"><span class="mord mtight">2</span></span></span></span><span class="vlist-s">​</span></span><span class="vlist-r"><span class="vlist" style="height:0.24414em;"><span></span></span></span></span></span></span><span class="mopen">(</span><span class="mord text"><span class="mord"><span class="mord mathit">B</span><span class="mord mathit">i</span><span class="mord mathit">g</span><span class="mord mathit">I</span><span class="mord mathit">n</span><span class="mord mathit">t</span><span class="mord mathit">e</span><span class="mord mathit">g</span><span class="mord mathit">e</span><span class="mord mathit">r</span></span></span><span class="mclose">)</span></span></span></span>';
const tex = '<span class="katex"><span class="katex-mathml"><math xmlns="http://www.w3.org/1998/Math/MathML"><semantics><mrow><mtext>TeX</mtext></mrow><annotation encoding="application/x-tex">\\TeX</annotation></semantics></math></span><span class="katex-html" aria-hidden="true"><span class="base"><span class="strut" style="height:0.89883em;vertical-align:-0.2155em;"></span><span class="mord text"><span class="mord textrm">T</span><span class="mspace" style="margin-right:-0.1667em;"></span><span class="vlist-t vlist-t2"><span class="vlist-r"><span class="vlist" style="height:0.46782999999999997em;"><span style="top:-2.7845em;"><span class="pstrut" style="height:3em;"></span><span class="mord"><span class="mord textrm">E</span></span></span></span><span class="vlist-s">​</span></span><span class="vlist-r"><span class="vlist" style="height:0.2155em;"><span></span></span></span></span><span class="mspace" style="margin-right:-0.125em;"></span><span class="mord textrm">X</span></span></span></span></span>';

export default function CvRu() {
    return (
        <Layout>
            <Header>
                <Header.Item>
                    <Header.Link href="/" fontSize={2} className="no-print">
                        <StyledOcticon icon={BriefcaseIcon} verticalAlign="middle" size={32} mr={2}/>
                        <span>Бояршин Андрей</span>
                    </Header.Link>
                    <Text className="only-print" fontSize={3} color={'black'}>Бояршин Андрей</Text>
                </Header.Item>
                <Header.Item className="only-print" px={1}/>
                <Header.Item>
                    <Header.Link href="https://github.com/andrew-boyarshin" className="no-print">
                        <StyledOcticon icon={MarkGithubIcon} verticalAlign="middle" mr={2}/>
                        <span>GitHub</span>
                    </Header.Link>
                    <StyledOcticon className="only-print" color='black' icon={MarkGithubIcon} verticalAlign="middle"
                                   mr={2}/>
                    <Text className="only-print" color={'black'}>andrew-boyarshin</Text>
                </Header.Item>
                <Header.Item>
                    <Header.Link href="https://t.me/andrew_boyarshin" className="no-print">
                        <StrokeSpan strokeColor={'white'}>
                            <TelegramIcon/>
                        </StrokeSpan>
                        <span>Telegram</span>
                    </Header.Link>
                    <StrokeSpan className="only-print" strokeColor={'black'}>
                        <TelegramIcon/>
                    </StrokeSpan>
                    <Text className="only-print" color={'black'}>andrew_boyarshin</Text>
                </Header.Item>
                <Header.Item>
                    <Header.Link href="mailto:andrew.boyarshin+cv@gmail.com" className="no-print">
                        <StyledOcticon icon={MailIcon} verticalAlign="middle" mr={2}/>
                        <span>E-mail</span>
                    </Header.Link>
                    <StyledOcticon className="only-print" color='black' icon={MailIcon} verticalAlign="middle" mr={2}/>
                    <Text className="only-print" color={'black'}>andrew.boyarshin+cv@gmail.com</Text>
                </Header.Item>
                <Header.Item full/>
                <Header.Item mr={0}>
                    <Text className="black-on-print" fontSize={2}>Резюме</Text>
                </Header.Item>
            </Header>
            <Box bg="white" color="black" px={4} pt={6} pb={64}>
                <Text as="p" className="no-print" mb={4}>
                    Русская версия. <Link to="/cv-en">Перейти к английской</Link>.
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MilestoneIcon} verticalAlign="middle" mr={1}/> Цель
                </Heading>
                <Text as="p" mb={6}>
                    Участвовать в разработке низкоуровневых стеков технологий: ядер операционных систем, виртуальных
                    машин для управляемого кода,
                    компиляторов.
                    Получить соответствующий опыт и провести исследовательскую работу, результаты которой можно
                    использовать как бакалаврскую дипломную работу.
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" mr={1}/> Образование
                </Heading>
                <Text as="p">
                    Бакалавриат, ожидаемая дата выпуска — июнь 2022
                </Text>
                <Text as="p" mb={6}>
                    Новосибирский Государственный Университет, Механико-математический факультет, направление Математика
                    и компьютерные науки
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MarkGithubIcon} verticalAlign="middle" mr={1}/> Проекты
                </Heading>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        <a href="https://github.com/hvanbakel/CsprojToVs2017">CsprojToVs2017</a> — <Tooltip
                        aria-label="№1 по количеству добавленных/удалённых строк"
                        className="tooltip-dotted">крупнейший</Tooltip> <a
                        href="https://github.com/hvanbakel/CsprojToVs2017/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">участник</a> и <Tooltip
                        aria-label="Право push в master и выпуска новых версий"
                        className="tooltip-dotted">co-maintainer</Tooltip>
                    </Text>
                    <Text as="p">
                        ПО для преобразования проектов из старого формата в формат Visual Studio 2017+.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#, MSBuild
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        <a href="https://github.com/SharpGenTools/SharpGenTools">SharpGenTools</a> — <Tooltip
                        aria-label="№3 по количеству коммитов и добавленных/удалённых строк" className="tooltip-dotted">3-ий
                        по вкладу</Tooltip> <a
                        href="https://github.com/SharpGenTools/SharpGenTools/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">участник</a> и <Tooltip
                        aria-label="Право push в master и выпуска новых версий"
                        className="tooltip-dotted">co-maintainer</Tooltip>
                    </Text>
                    <Text as="p">
                        Точный и высокопроизводительный генератор C# биндингов к коду на C и C++.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#, MSBuild
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        <a href="https://github.com/andrew-boyarshin/LoaderWatch">LoaderWatch</a> — автор
                    </Text>
                    <Text as="p">
                        Набор утилит для отладки, трассировки и разбора структур в памяти загрузчика PE образов Windows
                        (LDR), компонента NTDLL.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C, C#, разработка драйверов
                        режима ядра Windows, reverse-engineering
                    </Text>
                </Box>
                <Box className="no-break-block" mb={6}>
                    <Text as="p">
                        <a href="https://github.com/andrew-boyarshin/reactos">ReactOS</a> — maintainer форка & <a
                        href="https://github.com/reactos/reactos/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">участник</a>
                    </Text>
                    <Text as="p">
                        Свободная Windows-совместимая операционная система, <Tooltip aria-label="Windows Vista и новее"
                                                                                     className="tooltip-dotted">NT6</Tooltip>-нацеленный
                        форк с <Tooltip aria-label="исключая код сторонних зависимостей"
                                        className="tooltip-dotted">изменениями</Tooltip> вплоть до 62 <Tooltip
                        aria-label="тысяч строк" className="tooltip-dotted">KLoC</Tooltip> новых, 23 KLoC
                        удалённых строк <Tooltip aria-label="Возможна частичная дупликация в 2 ветках"
                                                 className="tooltip-dotted">в нескольких ветках</Tooltip>.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C, C++, внутреннее устройство
                        Windows, разработка ядра Windows, reverse-engineering
                    </Text>
                </Box>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MarkGithubIcon} verticalAlign="middle" mr={1}/> Прочий примечательный вклад
                </Heading>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        MSBuild — <Tooltip aria-label="Pull Request" className="tooltip-dotted">PR</Tooltip> <a
                        href="https://github.com/dotnet/msbuild/pull/5552">Раскрытие свойств при импорте SDK (#5552)</a>
                    </Text>
                    <Text as="p">
                        Вышел в составе .NET 5.0. Расширяет возможности платформы для сборки MSBuild, широко
                        используемой Visual Studio и экосистемой .NET.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        .NET — новое API <a href="https://github.com/dotnet/runtime/issues/31308">BigInteger.GetBitLength()
                        (#31308)</a>, <a href="https://github.com/dotnet/runtime/pull/37870"><Tooltip
                        aria-label="Pull Request" className="tooltip-dotted">PR</Tooltip> с реализацией (#37870)</a>
                    </Text>
                    <Text as="p">
                        Успешное предложение нового API в стандартной библиотеке .NET, а также его реализация, <a
                        href="https://docs.microsoft.com/en-us/dotnet/api/system.numerics.biginteger.getbitlength">вошедшая</a> в
                        состав .NET 5.0. Быстрое и точное вычисление <span
                        dangerouslySetInnerHTML={{__html: log2}}/>.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> C#
                    </Text>
                </Box>
                <Box className="no-break-block" mb={4}>
                    <Text as="p">
                        Gogs — <Tooltip aria-label="Pull Request" className="tooltip-dotted">PR</Tooltip> <a
                        href="https://github.com/gogs/gogs/pull/3233">Реализация зеркалирования Wiki (#3233)</a>
                    </Text>
                    <Text as="p">
                        Расширение возможностей зеркалирования в self-hosted сервисе Git.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> Go
                    </Text>
                </Box>
                <Box className="no-break-block" mb={6}>
                    <Text as="p">
                        <Tooltip aria-label="Форк Gogs" className="tooltip-dotted">Gitea</Tooltip> — Несколько <Tooltip
                        aria-label="Pull Request" className="tooltip-dotted">PR</Tooltip>, связанных с рендерингом
                        Markdown (<a
                        href="https://github.com/go-gitea/gitea/pulls?q=sort%3Aupdated-desc+author%3Aandrew-boyarshin">#186,
                        #980, #1027, #1043</a>)
                    </Text>
                    <Text as="p">
                        Улучшения пост-обработки разметки Markdown для self-hosted сервиса Git.
                    </Text>
                    <Text as="p">
                        <StyledOcticon icon={CodeIcon} verticalAlign="middle" mr={1}/> Go
                    </Text>
                </Box>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={PlusIcon} verticalAlign="middle" mr={1}/> Дополнительный опыт
                </Heading>
                <Box className="no-break-block" mb={6}>
                    <Text as="p">
                        Преподавание C/C++/C# в летних школах <a href="https://ssyp.ru/">ЛШЮП</a> (на волонтёрской
                        основе) и <a href="http://www.education.nsu.ru/letka/">ЛШ СУНЦ НГУ</a> (оплачиваемая
                        работа)
                    </Text>
                    <Text as="p">
                        Один раз как помощник преподавателя (<a
                        href="https://github.com/ssyp-ru/ssyp18-ws07">ЛШЮП-2018</a>),
                        три раза как преподаватель (<a href="https://github.com/ssyp-ru/ssyp19-ws03">ЛШЮП-2019</a>, <a
                        href="https://github.com/ssyp-ru/ssyp20-ws09">ЛШЮП-2020</a>, СУНЦ-2020). Ученики 12-17 летнего
                        возраста.
                    </Text>
                </Box>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={CodeSquareIcon} verticalAlign="middle" mr={1}/> Знание языков программирования
                </Heading>
                <Text as="p">
                    Хорошее: C#, C
                </Text>
                <Text as="p">
                    Неплохое: C++
                </Text>
                <Text as="p">
                    Обычное: Java, Python, JavaScript & TypeScript, разметка UI (XAML)
                </Text>
                <Text as="p">
                    Достаточное: Go, Kotlin, Pascal, Lua, web-разметка (HTML и CSS)
                </Text>
                <Text as="p" mb={6}>
                    Основы: F#, PHP, .NET IL, x86 ассемблер, прочие языки
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={TypographyIcon} verticalAlign="middle" mr={1}/> Знание разговорных языков
                </Heading>
                <Text as="p" mb={6}>
                    Русский (родной), английский (<Tooltip
                    aria-label="Cambridge Certificate in Advanced English, Grade B, 2018-05-12"
                    className="tooltip-dotted">C1</Tooltip>), немецкий (<Tooltip aria-label="DSD I, 2016"
                                                                                 className="tooltip-dotted">B1</Tooltip>)
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={MortarBoardIcon} verticalAlign="middle" mr={1}/> Достижения в учёбе
                </Heading>
                <Text as="p">
                    Оценки в университете исключительно «4» и «5».
                </Text>
                <Text as="p" mb={6}>
                    <a href="http://issc.nsu.ru/">Международная научная студенческая конференция 2020</a>, <a
                    href="http://issc.nsu.ru/it">секция Информационные технологии</a> — <Link to="/issc-20-thesis.pdf">«Верификация
                    моделей и алгоритмы анализа потока данных в статическом анализе исходного программного кода»</Link> —
                    диплом 2 степени в подсекции «Программная архитектура и системное программирование». Научный
                    руководитель — В. В. Соловьёв.
                </Text>

                <Heading className="no-break-after" fontSize={4}>
                    <StyledOcticon icon={KebabHorizontalIcon} verticalAlign="middle" mr={1}/> Прочее
                </Heading>
                <Text as="p">
                    Участие в нескольких финалах <a href="http://neerc.secna.ru/school/index.php">школьного NEERC ACM
                    ICPC</a> как член команды из 3 человек (дважды как капитан команды), диплом 2 степени по Сибирскому
                    региону в <a href="http://neerc.secna.ru/school/2017/VKOSP_Barn_2017.htm">2017</a> году.
                </Text>
                <Text as="p">
                    Обладание навыком чтения формальных спецификаций, стандартов языков, документации перед реализацией.
                </Text>
                <Text as="p">
                    Знакомство с <span dangerouslySetInnerHTML={{__html: tex}}/>, оболочками командной строки
                    (PowerShell, zsh, bash) и <Tooltip aria-label="Системы контроля версий"
                                                       className="tooltip-dotted">SCM</Tooltip> утилитами (Git, SVN,
                    Mercurial).
                </Text>
                <Text as="p">
                    Самоизоляция не ухудшает продуктивность.
                </Text>
            </Box>
        </Layout>
    )
}